.create-agent-wrapper {
  font-family: "DotGothic16", sans-serif;
  margin: 0 200px;
}
.create-agent-wrapper h1 {
  line-height: 2;
}
.inner-form-create-agent input,
.inner-form-create-agent select {
  width: 80%;
  position: relative;
  font-size: 18px;
  padding: 18px 28px;
  border-radius: 12px;
  border: none;
  background-color: #ededed;
  margin-bottom: 10px;
}

.inner-form-create-agent textarea {
  width: 80%;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 400;
  border-radius: 10px;
  color: #242424;
  background-color: #ededed;
  border: 1px solid transparent;
  padding: 0.875rem;
  height: 300px;
  line-height: 1.5;
  padding-top: 0.6125rem;
}
select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #ededed;
  background-image: url("https://static.thenounproject.com/png/551749-200.png");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 20px 20px;
  padding-right: 40px;
  font-size: 0.875em;
  padding: 18px 20px;
  border-radius: 12px;
  border: none;
}

.icon-create {
  width: 20px;
  background-color: transparent;
  margin-left: 6px;
}
.create-agent-btn {
  border: none;
  background-color: transparent;
  color: #474747;
  font-size: 17px;
}
.create-agent-btn:hover {
  background-color: transparent;
}
@media (max-width: 768px) {
  .create-agent-wrapper {
    margin: 20px;
  }
  .form-create input,
  .form-create select,
  .form-create textarea {
    width: 100%;
  }
}
