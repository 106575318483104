.agent-lib-wrapper {
  font-family: "DotGothic16", sans-serif;
  display: flex;
  flex-direction: column;

}

.agent-lib-inner {
  padding: 0 200px;
  flex: 1;
}
hr {
  border: 1px solid black;
  margin: 20px 0;
  align-items: center;
  display: flex;
  width: 100%;
}

ul li {
  list-style: none;
}

.list-nav ul {
  display: flex;
  gap: 60px;
  font-size: 20px;
  margin: 20px 0;
}

.list-nav ul li:hover {
  cursor: pointer;
  text-decoration: underline;
}

.agent-lib-table {
  margin: 40px 0;
  min-height: 46vh;
}

.table-lib th {
  width: 200px;
  border-bottom: solid 2px #474747;
  font-size: 20px;
  padding-bottom: 10px;
}

tbody tr:not(:last-child) {
  border-bottom: solid 1px #474747;
}
.body-table {
  max-height: 500px;
  overflow: scroll;
}
.body-table tr td {
  padding: 20px 0px;
}

.footer {
  margin-top: 40px;
}

.pagination {
  display: flex;
  justify-content: center;
  padding: 10px 0;
  list-style: none;
}

.page-item {
  margin: 0 5px;
}

.page-link {
  color: #007bff;
  cursor: pointer;
  padding: 5px 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.page-link:hover {
  background-color: #f2f2f2;
}

.table a {
  color: #007bff;
  text-decoration: none;
}

.table a:hover {
  text-decoration: underline;
}

.table button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.table button:hover {
  background-color: #0056b3;
}
.body-table a {
  text-decoration: none;
  color: #696969;
}
.chat-agent {
  background-color: #0077b5;
  color: white;
  border: none;
  padding: 2px 6px;
  cursor: pointer;
  border-radius: 4px;
}

.agent-lib-inner {
  opacity: 0;
  animation: fadeInContent 1s forwards;
  min-height: 100vh;
}

@media (max-width: 768px) {
  .agent-lib-wrapper {
    margin: 20px;
  }
  .total-agent {
    margin-bottom: 0;
    font-size: 12px;
  }
  .agent-lib-inner {
    padding: 0;
  }
  .body-table tr td {
    padding: 10px 0;
  }
  .agent-lib-table {
    width: 100%;
    margin: 0;
  }
  .agent-lib-table th {
    font-size: 12px;
  }
  .agent-lib-table td {
    font-size: 10px;
  }
  .list-nav ul {
    font-size: 10px;
    margin: 10px 0;
  }
}
